import { Base } from './base.js';

export class Logout extends Base {
    constructor() {
        super();
        this.logouButton = document.querySelector('.btn.logout-button');
        this.errorField =  document.querySelector('.form-error.form-error-logout');
        this.init();
    }

    init() {
        this.pushHistoryState('logout');

        if (this.logouButton) {
            this.logouButton.addEventListener('click', (event) => {
                event.preventDefault();
                this.logout();
            });
        }
    }

    logout() {
        this.buttonSpinner(this.logouButton);

        let formData = {
            accessToken: CONFIG.accessToken,
        };

        let request = new Request('POST', '/api/logout');
        request.data = formData;
        request.call(response => {
            if (response.status == 'ok') {
                this.utpCookie = false;
                document.location.href = this.baseUrl + "/" +  window.location.search;
            } else if (response.status == 'error') {
                if (response.message == "Token was disabled") {
                    document.location.href = this.baseUrl + "/" +  window.location.search;
                } else {
                    this.errorField.innerHTML = "<p>Der Server meldete einen Fehler:<br />" + response.message + "<br />&nbsp;<br /><a href='" + this.baseUrl + "/" +  window.location.search + "'>Zurück zum Login</a><p>";
                    this.showBlock(this.errorField);
                }
            }
            this.buttonSpinner(this.logouButton, false);
        }, () => {
            this.buttonSpinner(this.logouButton, false);
        });
    }
}